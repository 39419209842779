$border-color: #000;
.LiCSSsLiveEditor {
    &,
    & * {
        box-sizing: border-box;
    }
    //editor
    border: 1px $border-color solid;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100vw;
    &__toolbar,
    &__editor-input,
    &__editor-preview {
        padding: 10px 15px;
        max-width: 100%;
        overflow: visible;
        word-wrap: break-word;
    }
    &__toolbar {
        border-bottom: 1px $border-color solid;
        display: grid;
        grid-template-columns: auto 1fr auto;
        &-inline {
            padding: 0 10px;
            &--modal {
                background-color: #fff;
                padding: 5px;
                position: absolute;
            }
        }
    }
    &__editor {
        max-width: 100vw;
        &-input {
            border-right: 1px $border-color solid;
        }
        &-block {
            border: 0;
            margin: 0;
            height: 1.25em;
            outline: none;
            padding: 0;
            resize: none;
            overflow: hidden;
            width: 100%;
        }
    }
    &__footer {
        p {
            margin: 0;
            padding: 0;
        }
        border-top: 1px $border-color solid;
        font-size: .75em;
        padding: 5px;
        text-align: right;
    }
    //convert
    &__blockElements {
        &,
        & * {
            box-sizing: border-box;
        }
        max-width: 100%;
        overflow: visible;
        word-wrap: break-word;
        p {
            margin: 3px 0;
            min-height: 1em;
        }
    }
}